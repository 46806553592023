import React, { useEffect } from 'react'

import 'moment-timezone'
import { Redirect, Router } from '@reach/router'

import SemanticUIFull from 'components/shared/semantic-ui-full'
import SEO from 'components/shared/seo'
import Tracking from 'components/shared/tracking'
import fireEvent from 'context/tracking/events'
import { StyledContent } from 'styles/app/system'
import CalendlyCallback from 'views/welcome/calendly-callback'
import Index from 'views/welcome/index'
import Estimate from 'views/welcome/prospect/estimate'
import EstimateDimensions from 'views/welcome/prospect/estimate/steps/dimensions'
import EstimateInfo from 'views/welcome/prospect/estimate/steps/info'
import EstimateLayout from 'views/welcome/prospect/estimate/steps/layout'
import EstimateResults from 'views/welcome/prospect/estimate/steps/results'
import FreeConsultation from 'views/welcome/prospect/free-consultation-guide'
import ProspectIndex from 'views/welcome/prospect/index'
import HomeownerDashboardAdvisoryCall from 'views/welcome/prospect/onboarding-homeowner/dashboard/advisory-call'
import HomeownerDashboardDenverStudio from 'views/welcome/prospect/onboarding-homeowner/dashboard/denver-studio'
import HomeownerDashboardFloorPlan from 'views/welcome/prospect/onboarding-homeowner/dashboard/floor-plan'
import HomeownerDashboard from 'views/welcome/prospect/onboarding-homeowner/dashboard/index'
import HomeownerDashboardLocations from 'views/welcome/prospect/onboarding-homeowner/dashboard/locations'
import HomeownerDashboardNYStudio from 'views/welcome/prospect/onboarding-homeowner/dashboard/ny-studio'
import HomeownerDashboardProjectInformation from 'views/welcome/prospect/onboarding-homeowner/dashboard/project-information'
import HomeownerDashboardScheduleConsultation from 'views/welcome/prospect/onboarding-homeowner/dashboard/schedule-call'
import HomeownerDashboardSFStudio from 'views/welcome/prospect/onboarding-homeowner/dashboard/sf-studio'
import HomeownerProjectInfo from 'views/welcome/prospect/onboarding-homeowner/project-info'
import HomeownerProjectInfoFlow from 'views/welcome/prospect/onboarding-homeowner/project-info/flow'
import HomeownerStart from 'views/welcome/prospect/onboarding-homeowner/start'
import TradeIndex from 'views/welcome/prospect/onboarding-trade'
import TradeDashboard from 'views/welcome/prospect/onboarding-trade/dashboard'
import TradeDashboardCompanyInformation from 'views/welcome/prospect/onboarding-trade/dashboard/company-information'
import TradeDashboardContactInformation from 'views/welcome/prospect/onboarding-trade/dashboard/contact-information'
import TradeDashboardScheduleCall from 'views/welcome/prospect/onboarding-trade/dashboard/schedule-call'
import TradeOnboarding from 'views/welcome/prospect/onboarding-trade/flow'
import TradeOnboardingThankYou from 'views/welcome/prospect/onboarding-trade/thank-you'
import DashboardThankYou from 'views/welcome/prospect/schedule-thank-you'
import StartProject from 'views/welcome/prospect/start-project'
import StartProjectTrade from 'views/welcome/prospect/start-project-trade'
import Timeline from 'views/welcome/prospect/timeline'

const Welcome = () => {
  const isEnvTracked =
    process.env.GATSBY_ACTIVE_ENV === 'production' ||
    process.env.GATSBY_ACTIVE_ENV === 'stage'

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Welcome',
    })
  }, [])

  return (
    <StyledContent>
      <SemanticUIFull />
      <Tracking segment={!!isEnvTracked} />
      <Router>
        <Index path="/welcome">
          <HomeownerStart path="/free-video-call" />
          <TradeOnboarding path="/trade-onboarding/flow/*" />
          <TradeOnboardingThankYou path="/trade-onboarding/thank-you" />
          <ProspectIndex path="/:prospect_id">
            <HomeownerProjectInfo path="/project-info">
              <HomeownerProjectInfoFlow path="/*" />
            </HomeownerProjectInfo>
            <HomeownerDashboard default />
            <HomeownerDashboardProjectInformation path="project-information" />
            <HomeownerDashboardFloorPlan path="floor-plan" />
            <HomeownerDashboardScheduleConsultation path="schedule-call" />
            <HomeownerDashboardLocations path="locations" />
            <HomeownerDashboardAdvisoryCall path="advisory-call" />
            <HomeownerDashboardDenverStudio path="locations/denver" />
            <HomeownerDashboardNYStudio path="locations/new-york" />
            <HomeownerDashboardSFStudio path="locations/san-francisco" />
            <DashboardThankYou path="thank-you" />
            <FreeConsultation path="free-consultation" />
            <Estimate path="/estimate">
              <EstimateLayout path="layout" />
              <EstimateDimensions path="dimensions" />
              <EstimateInfo path="info" />
              <EstimateResults path="results" />
            </Estimate>
            <StartProject path="start-project" />
            <StartProjectTrade path="start-project-trade" />
            <Timeline path="timeline" />
            <TradeIndex path="/trade">
              <TradeDashboard default />
              <TradeDashboardContactInformation path="contact-information" />
              <TradeDashboardCompanyInformation path="company-information" />
              <TradeDashboardScheduleCall path="schedule-call" />
              <DashboardThankYou path="thank-you" />
            </TradeIndex>
          </ProspectIndex>
          <CalendlyCallback path="get-prospect" />
          <Redirect noThrow from="deposit" to="/welcome/new/start-project" />
          <Redirect
            noThrow
            from="trade-profile"
            to="/welcome/trade-onboarding/flow"
          />
          <Redirect
            noThrow
            from="new/consultation/start"
            to="/welcome/new/project-info/get-started/"
          />
        </Index>
      </Router>
    </StyledContent>
  )
}

export default Welcome

export const Head = () => (
  <SEO title="Welcome" description="" meta={[]} lang="" />
)
