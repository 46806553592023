import React, { useEffect } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import Header from 'components/app/project/header/header-basic'
import HubspotInlineScheduler from 'components/shared/meeting/hubspot-inline-scheduler'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'

const SfStudio = (_: RouteComponentProps) => {
  const { prospect, hasProject, designer, refetch, startPolling, stopPolling } =
    useProspect()

  const handleEventScheduled = () => {
    startPolling(2000)
    navigate(`/welcome/${prospect?.id}/thank-you`)
  }

  if (!prospect || !prospect?.id) {
    navigate('/welcome/new/project-info/get-started/')
  }

  useEffect(() => {
    if (hasProject && designer) {
      stopPolling()
      refetch()
      navigate(`/welcome/${prospect?.id}/thank-you`)
    }
  }, [prospect, designer])

  return (
    <AppLayout
      header={<Header onBack={() => navigate?.(`/welcome/${prospect?.id}`)} />}
    >
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Choose a Date and Time</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <HubspotInlineScheduler
              calendarUrl={process.env.GATSBY_HUBSPOT_SF_STUDIO_CALL_URL ?? ''}
              prospect={prospect}
              onEventScheduled={() => {
                handleEventScheduled()
                fireEvent({
                  type: 'identify',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  phone: prospect?.phone ?? '',
                  state: prospect?.data.state ?? '',
                  zipCode: prospect?.zipCode ?? '',
                })
                fireEvent({
                  type: 'schedule_completed',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  meetingType: 'SELECTION_CENTER',
                  phone: prospect?.phone,
                  state: prospect?.data.state,
                  studio: 'San Francisco',
                  zipCode: prospect?.zipCode,
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default SfStudio
