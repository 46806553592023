import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import Header from 'components/app/project/header/header-basic'
import FormHeader from 'components/shared/header-form'
import HubspotInlineScheduler from 'components/shared/meeting/hubspot-inline-scheduler'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'

const ScheduleCall = (_: RouteComponentProps) => {
  const { designer, hasProject, prospect, startPolling, stopPolling, refetch } =
    useProspect()

  if (!prospect) {
    navigate(`/welcome/new/project-info/get-started/`)
  }
  if (hasProject) {
    navigate(`/login-user/?email=${encodeURIComponent(prospect?.email ?? '')}`)
  }

  const handleEventScheduled = () => {
    startPolling(2000)
    navigate(`/welcome/${prospect?.id}/thank-you`)
  }

  useEffect(() => {
    if (hasProject && designer) {
      stopPolling()
      refetch()
      navigate(`/welcome/${prospect?.id}/thank-you`)
    }
  }, [prospect, designer])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <AppLayout
      header={
        prospect?.id ? (
          <Header onBack={() => navigate?.(`/welcome/${prospect.id}`)} />
        ) : (
          <FormHeader />
        )
      }
    >
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Choose a Date and Time</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <HubspotInlineScheduler
              calendarUrl={process.env.GATSBY_HUBSPOT_ONLINE_CALL_URL ?? ''}
              prospect={prospect}
              onEventScheduled={() => {
                handleEventScheduled()
                fireEvent({
                  type: 'identify',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  phone: prospect?.phone ?? '',
                  state: prospect?.data.state ?? '',
                  zipCode: prospect?.zipCode ?? '',
                })
                fireEvent({
                  type: 'schedule_completed',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  meetingType: 'DESIGN',
                  phone: prospect?.phone,
                  state: prospect?.data.state,
                  zipCode: prospect?.zipCode,
                })
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default ScheduleCall
