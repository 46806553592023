import React, { useEffect, useState } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import PaymentModule from 'components/shared/payment-module'
import Header from 'components/welcome/header'
import StartProjectCheckout from 'components/welcome/prospect/start-project-checkout'
import useProspect from 'context/prospect/use'
import AppLayout from 'design-system/containers/app-layout'
import { PaymentType } from 'types/payment'

import ThankYouPage from './thank-you'

const StartProject = (_: RouteComponentProps) => {
  const [completedEmail, setCompletedEmail] = useState<string>()
  const { prospect, refetch } = useProspect()

  const affiliateCode = prospect?.data?.affiliateCode

  useEffect(() => {
    refetch()
  }, [])

  if (completedEmail) return <ThankYouPage email={completedEmail} />

  return (
    <AppLayout
      header={
        affiliateCode ? (
          <Header noExit />
        ) : (
          <Header onBack={() => navigate?.(`/welcome/${prospect?.id}`)} />
        )
      }
    >
      <PaymentModule
        paymentRequest={
          <StartProjectCheckout
            onCompleted={(email: string) => setCompletedEmail(email)}
          />
        }
        paymentType={PaymentType.PROJECT_DEPOSIT}
        title="Purchase Your FORM Design Package"
        subtitle="Pay $499 now. If it’s not a match after your first call, get a full refund."
      />
    </AppLayout>
  )
}

export default StartProject
