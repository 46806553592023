import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'

import { StepperProps } from 'design-system/components/stepper'
import YourDetailsView from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/views/details'
import GetStartedView from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/views/get-started'
import LocationsView from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/views/locations'

export type ProspectFlowRouteProps = RouteComponentProps<{
  '*': string
}>

export type StepViewProps = ProspectFlowRouteProps & {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setOnNext: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >
}

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<StepViewProps>
}
type StartProspectStepsType = 'getStarted' | 'locations' | 'yourDetails'

export const PROSPECT_QUIZ_STEPS: Record<
  StartProspectStepsType,
  NavigationStep
> = {
  getStarted: {
    label: 'Get Started',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'get-started',
    View: GetStartedView,
  },
  locations: {
    label: 'Locations',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'locations',
    View: LocationsView,
  },
  yourDetails: {
    label: 'Your details',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'your-details',
    View: YourDetailsView,
  },
}

export const PROSPECT_QUIZ_STEPS_ARRAY = [
  PROSPECT_QUIZ_STEPS.getStarted,
  PROSPECT_QUIZ_STEPS.locations,
  PROSPECT_QUIZ_STEPS.yourDetails,
]
