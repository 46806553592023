import React, { useEffect, useState } from 'react'

import Helmet from 'react-helmet'

const scriptURL =
  'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'

const ScriptLoader = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  useEffect(() => {
    const existingScript = document.querySelector(`script[src="${scriptURL}"]`)
    if (existingScript) {
      setScriptLoaded(true)
    }
  }, [])
  return (
    <>
      <Helmet>{!scriptLoaded && <script async defer src={scriptURL} />}</Helmet>
    </>
  )
}

export default ScriptLoader
