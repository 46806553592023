import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import fireEvent from 'context/tracking/events'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import { Breakpoint } from 'styles/app/system'
import { QuizAnswerCategory } from 'types/prospect'
import { QuizContext } from 'views/welcome/prospect/onboarding-homeowner/project-info'
import { StepViewProps } from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/steps'

const GetStartedView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { dispatch, state } = useContext(QuizContext)
  const onChange = (identifier: string) => {
    dispatch({
      type: 'toggle_answer_one_per_category',
      answer: {
        category: QuizAnswerCategory.LOCATION,
        value: identifier,
      },
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'LOCATION',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(
      !state.answers.find((c) => c.category === QuizAnswerCategory.LOCATION),
    )
  }, [state])

  return (
    <>
      <Container>
        <StyledGrid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3>Pick a Studio to Visit</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row stretched styled={{ margin: '0 5px' }}>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectImageCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/new-york-studio.webp`}
                onClick={() => onChange('new-york' ?? '')}
                selected={!!state.answers.find((c) => c.value === 'new-york')}
                text={'New York'}
                subtitle={'NY Design Center 200 Lexington Ave. Suite 429'}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectImageCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/denver-studio.webp`}
                onClick={() => onChange('denver' ?? '')}
                selected={!!state.answers.find((c) => c.value === 'denver')}
                text={'Denver'}
                subtitle={'2624 W 32nd'}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectImageCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/san-francisco-studio.webp`}
                onClick={() => onChange('san-francisco' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'san-francisco')
                }
                text={'San Francisco'}
                subtitle={
                  'SF Design Center Galleria 101 Henry Adams St. Suite 376'
                }
              />
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
      </Container>
    </>
  )
}

export default GetStartedView

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
      .row {
        row-gap: 24px !important;
      }
    }
    .row {
      margin-bottom: 25px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
