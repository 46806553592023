import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import ThankYou from 'components/welcome/prospect/thank-you'
import useProspect from 'context/prospect/use'

const ScheduleThankYouPage = (_: RouteComponentProps) => {
  const { prospect } = useProspect()

  if (!prospect || !prospect?.id) {
    navigate('/')
  }

  return (
    <ThankYou
      buttonText="Back to Main Page"
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/modern-white-kitchen.webp`}
      title="Thanks for Scheduling Your Visit"
      message={`A confirmation email to ${prospect?.email} is on its way.`}
      onClick={() => navigate('/')}
    />
  )
}

export default ScheduleThankYouPage
