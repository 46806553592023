import React from 'react'

import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

const DesignersHeader = ({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) => {
  return (
    <HeaderContainer>
      <div>
        <h3 className="no-margin">{title}</h3>
        {subtitle && <p className="light-gray">{subtitle}</p>}
      </div>
    </HeaderContainer>
  )
}

export default DesignersHeader

const HeaderContainer = styled.div`
  display: grid;
  align-items: center;

  & > h1 {
    font-size: 36px;
    font-weight: 300;
    margin: 0;
  }

  & > div.actions {
    display: flex;
    column-gap: 32px;
  }

  & > p {
    color: ${Colors.gray600};
    font-weight: 300;
    margin: 0;
  }

  @media ${Breakpoint.onlyMobile} {
    & > div.actions {
      column-gap: 8px;
      grid-column: 1 / span 2;
      justify-content: space-around;
      margin-top: 8px;
    }

    & > p {
      grid-column: 1 / span 2;
      text-align: center;
    }
  }
`
