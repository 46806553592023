import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { Form } from 'semantic-ui-react'

import PaymentCard from 'components/shared/project/payment/card'
import Checkout from 'components/welcome/checkout'
import { notifyError } from 'context/notifications/trigger'
import useProjectMutateNoContext from 'context/project/use-mutate-no-context'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import { StyledForm } from 'styles/app/components/form'
import { PaymentMethodType, PaymentType } from 'types/payment'
import { PaymentSource } from 'types/payment-source'
import { ProjectType } from 'types/project'
import { ProjectUserRoleHomeowner } from 'types/project-user'
import { UserTypeHomeowner } from 'types/user'
import { emailIsValid } from 'views/utils'

interface StartProjectCheckoutProps {
  onCompleted?: (email: string) => void
}

const StartProjectCheckout = ({ onCompleted }: StartProjectCheckoutProps) => {
  const { prospect, hasProject } = useProspect()
  if (!prospect) {
    navigate(`/welcome/new/project-info/get-started/`)
  }
  if (hasProject) {
    navigate(`/login-user/?email=${encodeURIComponent(prospect?.email ?? '')}`)
  }
  const [state, setState] = useState({
    email: prospect?.email.trim().toLocaleLowerCase() || '',
    emailInputError: false,
  })
  const { createProject, loadingCreate } = useProjectMutateNoContext()

  const payment = {
    amount: 49900,
    description: 'FORM Design Package',
    metadata: {
      method: PaymentMethodType.CREDIT_CARD,
      type: PaymentType.PROJECT_DEPOSIT,
    },
  }

  const onPay = async (paymentSource?: PaymentSource) => {
    await createProject({
      variables: {
        data: {
          data: {
            projectType: ProjectType.HOMEOWNER,
          },
          owners: {
            create: [
              {
                isSubscribed: true,
                isPrimary: true,
                userRole: ProjectUserRoleHomeowner.HOMEOWNER,
                user: {
                  create: {
                    data: {
                      inTradeProgram: false,
                    },
                    email: state.email,
                    type: UserTypeHomeowner.HOMEOWNER,
                  },
                },
              },
            ],
          },
          payments: {
            create: {
              ...payment,
              stripeSourceId: paymentSource?.id,
            },
          },
          prospects: prospect
            ? {
                create: [{ prospect: { connect: { id: prospect.id } } }],
              }
            : undefined,
        },
      },
      onCompleted: async () => {
        fireEvent({
          type: 'project_deposit_completed',
          email: state.email,
        })
        onCompleted?.(state.email)
      },
      onError: ({ message }) => {
        notifyError(message)
      },
    })
  }

  return (
    <>
      <StyledForm>
        <Form.Input
          {...(state.emailInputError && {
            error: {
              content: (
                <p className="red small">Please enter a valid email address</p>
              ),
            },
          })}
          label="Email"
          name="email"
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            setState({
              ...state,
              email: value.toLowerCase(),
              emailInputError: false,
            })
          }}
          onBlur={() =>
            setState({
              ...state,
              emailInputError: !emailIsValid(state.email),
            })
          }
          placeholder="Email"
          disabled={!!prospect?.email}
          required
          value={state.email}
        />
      </StyledForm>
      <Checkout
        buttonText="Complete Purchase"
        disabled={!emailIsValid(state.email)}
        includeInvoice={false}
        loading={loadingCreate}
        onPay={onPay}
        summary={<PaymentCard payment={payment} />}
        summaryTitle="Payment Request"
      />
    </>
  )
}

export default StartProjectCheckout
