import React, { useContext, useEffect } from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import fireEvent from 'context/tracking/events'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Breakpoint } from 'styles/app/system'
import { QuizAnswerCategory } from 'types/prospect'
import { QuizContext } from 'views/welcome/prospect/onboarding-homeowner/project-info'
import { StepViewProps } from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/steps'

const GetStartedView = ({ setNextDisabled, setOnNext }: StepViewProps) => {
  const { dispatch, state } = useContext(QuizContext)
  const onChange = (identifier: string) => {
    dispatch({
      type: 'toggle_answer_one_per_category',
      answer: {
        category: QuizAnswerCategory.GET_STARTED,
        value: identifier,
      },
    })
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    window.scrollTo(0, 0)
    const onNext = async () => {
      fireEvent({
        type: 'quiz_step_completed',
        stepName: 'GET STARTED',
      })
    }
    setOnNext(() => onNext)
  }, [])

  useEffect(() => {
    setNextDisabled(
      !state.answers.find((c) => c.category === QuizAnswerCategory.GET_STARTED),
    )
  }, [state])

  return (
    <>
      <Container>
        <StyledGrid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3>Three Ways to Get Started</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row stretched>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectIconCard
                icon="users"
                onClick={() => onChange('studio-visit' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'studio-visit')
                }
                text="Visit Us in Person"
              >
                <p className="gray no-margin">
                  Explore our cabinetry and experience the quality firsthand at
                  our San Francisco, New York, or Denver studios.
                </p>
              </SelectIconCard>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectIconCard
                icon="video-camera"
                onClick={() => onChange('online-book' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'online-book')
                }
                text="Virtual Studio Tour"
              >
                <p className="gray no-margin">
                  Connect with the studio manager through a video call to
                  explore cabinetry and get answers to any questions you have.
                </p>
              </SelectIconCard>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={8}>
              <SelectIconCard
                icon="credit-card"
                onClick={() => onChange('start-project' ?? '')}
                selected={
                  !!state.answers.find((c) => c.value === 'start-project')
                }
                text="Start with a Designer"
              >
                <p className="gray no-margin">
                  Purchase a $499 design package to begin working directly with
                  a designer. If FORM isn’t a fit after your first call, you’ll
                  get a full refund.
                </p>
              </SelectIconCard>
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
      </Container>
    </>
  )
}

export default GetStartedView

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
      .row {
        margin-bottom: 20px !important;
        row-gap: 24px !important;
      }
    }
    .row {
      margin-bottom: 50px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
