import React from 'react'

import { navigate } from 'gatsby'

import ThankYou from 'components/welcome/prospect/thank-you'

const ThankYouPage = ({ email }: { email: string }) => {
  return (
    <ThankYou
      buttonText="Go to Your Dashboard"
      image={`${process.env.GATSBY_CDN_ROOT}/images/app/graphite-kitchen-workspace.webp`}
      title="Your Design Package is Confirmed"
      subtitle="Thank you for choosing FORM."
      message={`We've emailed to ${email} your login. <br/>Please check spam if it’s not there.`}
      onClick={() =>
        navigate(`/login-user/?email=${encodeURIComponent(email)}`)
      }
    />
  )
}

export default ThankYouPage
