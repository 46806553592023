import React, { useContext, useEffect } from 'react'

import { useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import lodash from 'lodash'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Container, Form, Grid } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import LeadTimeProvider from 'context/lead-times/provider'
import { validateAffiliateCode } from 'context/project/utils-discounts'
import fireEvent from 'context/tracking/events'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import { useZipCodes } from 'context/zip-codes/provider'
import { ControlledPhoneInput } from 'design-system/components/form'
import {
  UPSERT_PROSPECT,
  UpsertProspectPayload,
  UpsertProspectVariables,
} from 'queries/prospect'
import { StyledForm } from 'styles/welcome/components/form'
import { FormContactSource } from 'types/crm'
import { QuizAnswerCategory } from 'types/prospect'
import { ProspectStaffRole } from 'types/prospect-staff'
import { StaffMemberAssignmentType } from 'types/utils'
import { emailIsValid, validateName } from 'views/utils'
import { QuizContext } from 'views/welcome/prospect/onboarding-homeowner/project-info'
import { StepViewProps } from 'views/welcome/prospect/onboarding-homeowner/project-info/flow/steps'

type ProspectInformationForm = {
  affiliateCode: string
  email: string
  firstName: string
  lastName: string
  phone: string
  zipCode: string
}

const YourDetailsView = ({
  setLoading,
  setNextDisabled,
  setOnNext,
}: StepViewProps) => {
  const { fetchUniqueZipCode, loading: loadingZipCode } = useZipCodes()
  const {
    affiliateCode,
    designerEmailPrefix,
    designerId,
    location,
    locationBy,
    state,
  } = useContext(QuizContext)
  const { state: utmState } = useContext(TrackingContext)
  const isLocationVisit =
    !!state.answers.find((c) => c.value === 'studio-visit') || location
  const isStartProject = !!state.answers.find(
    (c) => c.value === 'start-project',
  )

  const {
    control,
    setError,
    getValues,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<ProspectInformationForm>({
    mode: 'onTouched',
    defaultValues: {
      affiliateCode: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      zipCode: '',
    },
  })

  const [upsertProspect, { loading: loadingUpsert }] = useMutation<
    UpsertProspectPayload,
    UpsertProspectVariables
  >(UPSERT_PROSPECT, {
    onCompleted: ({ upsertProspect }) => {
      const newProspect = getValues()
      if (upsertProspect?.hubspotId) {
        fireEvent({
          type: 'identify',
          userId: String(upsertProspect.hubspotId),
          email: newProspect.email,
          firstName: newProspect.firstName,
          lastName: newProspect.lastName,
          phone: upsertProspect.phone ?? '',
          state: upsertProspect.data.state ?? '',
          zipCode: newProspect.zipCode ?? '',
        })
        fireEvent({
          type: 'prospect_upserted',
          hubspotId: upsertProspect.hubspotId,
        })
      }
      fireEvent({
        type: 'quiz_completed',
        email: newProspect.email,
        firstName: newProspect.firstName,
        lastName: newProspect.lastName,
        phone: upsertProspect.phone ?? '',
        state: upsertProspect.data.state ?? '',
        zipCode: newProspect.zipCode ?? '',
      })
      if (affiliateCode || isStartProject) {
        navigate(`/welcome/${upsertProspect.id}/start-project/`)
      } else if (isLocationVisit) {
        const studioSelected =
          state.answers.find((c) => c.category === QuizAnswerCategory.LOCATION)
            ?.value || location
        navigate(`/welcome/${upsertProspect.id}/locations/${studioSelected}/`)
      } else {
        navigate(`/welcome/${upsertProspect.id}/schedule-call/`)
      }
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          if (message.includes('INVALID_EMAIL')) {
            setError('email', { message: 'Invalid email address!' })
          }
        })
      }
      setLoading(false)
    },
  })

  const onSubmit: SubmitHandler<ProspectInformationForm> = async (
    newProspect,
  ) => {
    setLoading(true)

    return upsertProspect({
      variables: {
        data: {
          ...lodash.omit(
            newProspect,
            'affiliateCode',
            'location',
            'locationBy',
          ),
          data: {
            ...validateAffiliateCode(
              affiliateCode ||
                newProspect.affiliateCode ||
                utmState?.afmc ||
                '',
            ),
            form_contact_source: FormContactSource.QUIZ,
            location,
            locationBy,
            quizAnswers: state.answers,
            quizCompletedAt: new Date().toISOString(),
            shareASaleTrackingCode: getShareASaleClickId(),
          },
          dataTracking: {
            utmCampaign: utmState.utmccn,
            utmContent: utmState.utmcct,
            utmMedium: utmState.utmcmd,
            utmSource: utmState.utmcsr,
            utmTerm: utmState.utmctr,
            gclid: utmState.gclid,
          },
          ...((designerId || designerEmailPrefix) && {
            staff: {
              create: [
                {
                  data: {
                    staffMemberAssignmentType:
                      StaffMemberAssignmentType.REFERRED,
                  },
                  isPrimary: true,
                  isSubscribed: true,
                  staffMember: {
                    connect: {
                      ...(designerEmailPrefix && {
                        email: `${designerEmailPrefix}@formkitchens.com`,
                      }),
                      ...(!designerEmailPrefix &&
                        designerId && {
                          id: designerId,
                        }),
                    },
                  },
                  staffMemberRole: ProspectStaffRole.PROPOSED_DESIGNER,
                },
              ],
            },
          }),
        } as any,
      },
    })
  }

  useEffect(() => {
    !loadingUpsert && setLoading(loadingZipCode)
  }, [loadingZipCode, loadingUpsert])

  useEffect(() => {
    setNextDisabled(!isValid)
  }, [isValid])

  useEffect(() => {
    setOnNext(() => handleSubmit(onSubmit))
  }, [])

  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3>Share Your Details</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column computer={7} tablet={10} mobile={16}>
              <StyledForm styled={{ grayInputs: true }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) =>
                      validateName(value, {
                        acceptSpecialLetters: true,
                        errorMessage: 'Invalid name',
                      }),
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="First Name"
                      placeholder="First name"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) =>
                      validateName(value, {
                        acceptSpecialLetters: true,
                        errorMessage: 'Invalid last name',
                      }),
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Last Name"
                      placeholder="Last name"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) =>
                      emailIsValid(value) ||
                      'Please enter a valid email address',
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Email"
                      placeholder="Email"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                <ControlledPhoneInput
                  name="phone"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  label="Phone"
                  placeholder="Phone"
                />
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: {
                      invalid: (value) =>
                        /^(\d{9}|\d{5})$/.test(value) || 'Invalid zip code',
                      fetch: async (value) => {
                        const res = await fetchUniqueZipCode({
                          variables: { where: { zipCode: value } },
                        })
                        return (
                          !!res.data?.zipCode || "We don't deliver here yet"
                        )
                      },
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Zip Code"
                      placeholder="Zip Code"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                {affiliateCode && (
                  <Controller
                    name="affiliateCode"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value.trim()) {
                          return true
                        }
                        const isValid = !!validateAffiliateCode(value)
                        return isValid || 'Invalid referral code'
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Form.Input
                        label="Affiliate Code"
                        placeholder="Affiliate Code"
                        name={name}
                        disabled={!!affiliateCode || false}
                        value={value || affiliateCode}
                        error={
                          !!error?.message && {
                            content: (
                              <p className="red small">{error.message}</p>
                            ),
                          }
                        }
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                )}
              </StyledForm>
              {errors?.zipCode?.type === 'fetch' && (
                <HelpMessage
                  icon="circle-info"
                  title="We don't deliver here yet"
                  message="Sorry, we don’t currently deliver to your zip code yet. You can receive our orders anywhere in the continental US and handle transportation from there. Email hello@formkitchens.com for more information."
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default (props: StepViewProps) => {
  return (
    <LeadTimeProvider>
      <YourDetailsView {...props} />
    </LeadTimeProvider>
  )
}
