import React from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useMedia } from 'react-use'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import Button from 'design-system/components/button/button'
import { Breakpoint } from 'styles/app/system'

interface ThankYouProps {
  buttonText: string
  icon?: IconName
  image: string
  message?: string
  onClick: () => void
  subtitle?: string
  title: string
}
const ThankYou = ({
  buttonText,
  icon = 'lightbulb-on',
  image,
  message,
  onClick,
  subtitle,
  title,
}: ThankYouProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <StyledContainer>
      <Grid centered verticalAlign="middle">
        <Grid.Row>
          <StyledColumn mobile={14} tablet={12} computer={8}>
            {subtitle && <p className="gray no-margin">{subtitle}</p>}
            <h3
              className="no-margin"
              style={{
                textAlign: 'center',
              }}
            >
              {title}
            </h3>
            {message && <HelpMessage message={message} icon={icon} fit />}
            <Button
              color="dark"
              kind="solid"
              size={isMobile ? 'fluid' : 'small'}
              text={buttonText}
              onClick={onClick}
            />
          </StyledColumn>
          <Grid.Column
            only="computer"
            computer={8}
            style={{
              height: '100vh',
              padding: 0,
            }}
          >
            <StyledImage src={image} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledContainer>
  )
}

export default ThankYou

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;

  .grid {
    min-height: 100vh;
  }
`
const StyledColumn = styled(Grid.Column)`
  &&& {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
    align-items: center !important;
  }
`
const StyledImage = styled(Image)`
  &&&& {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
