'use strict'

import React, { useCallback, useEffect, useMemo } from 'react'

import styled from 'styled-components'

import ScriptLoader from 'components/shared/meeting/hubspot-loader'
import { Prospect as FORMProspect } from 'types/prospect'

const isHubspotMeetingsEvent = (e: MessageEvent) => {
  return e.origin?.includes('meetings.hubspot')
}

export interface InlineCalendarProps {
  calendarUrl: string
  onEventScheduled: () => void
  prospect?: FORMProspect | null
}

const HubspotInlineScheduler = ({
  calendarUrl,
  onEventScheduled,
  prospect,
}: InlineCalendarProps) => {
  const queryParams = new URLSearchParams({
    email: prospect?.email || '',
    firstname: prospect?.firstName || '',
    lastname: prospect?.lastName || '',
  }).toString()

  const calendarUrlWithParams = `${calendarUrl}?embed=true&${queryParams}`

  const handleMessageEvent = useCallback(
    (e: MessageEvent) => {
      if (isHubspotMeetingsEvent(e) && e.data?.meetingBookSucceeded) {
        onEventScheduled()
      }
    },
    [onEventScheduled],
  )

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent)

    return () => {
      window.removeEventListener('message', handleMessageEvent)
    }
  }, [handleMessageEvent])

  const ScheduleForm = useMemo(
    () => (
      <ScheduleFormContainer
        data-src={calendarUrlWithParams}
        className="meetings-iframe-container"
      />
    ),
    [calendarUrl],
  )

  return (
    <>
      {ScheduleForm}
      <ScriptLoader />
    </>
  )
}

export default HubspotInlineScheduler

const ScheduleFormContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  border: none;
`
